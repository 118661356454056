
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ProjectInviteDto} from "@/api/appService";
import api from "@/api";
import moment from "moment";

@Component({
  name: "projectInviteDetail"
})
export default class ProjectInviteDetail extends Vue {
  @Prop() detail?: ProjectInviteDto;
  @Prop({required: false, default: "rtl"}) direction!: string;
  @Prop() dataId?: number;
  loading = false;
  show = false;
  detailData: ProjectInviteDto = {};

  @Watch('detail')
  onDetailChange(newValue: ProjectInviteDto) {
    this.detailData = newValue;
  }

  @Watch('show')
  onShowChange(value: boolean) {
    if (!value)
      return;

    if (this.detail) {
      api.projectInvite.get({id: this.detail.id}).then(res => {
        this.detailData = res;
        this.loading = false;
      })
      return;
    }

    if (!this.dataId) {
      return;
    }
  }

  @Watch('dataId')
  onDataIdChange(newId?: number) {
    if (!newId) {
      this.detailData = {};
      return
    }
    this.loading = true;
    api.projectInvite.get({id: newId}).then(res => {
      this.detailData = res;
      this.loading = false;
    })
  }

  private handleClose() {
    this.show = false;
  }
}
