
import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {ProjectInviteCreateDto, ProjectSeriesDto, ProjectSeriesType} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";
import RoleSelect from "@/components/RoleSelect/index.vue";

@Component({
  components: {RoleSelect, OrganizationSelect, Tinymce, SimpleUploadImage, AbSelect}
})
export default class CreateProjectInvite extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Prop({required: true})
  dataId!: number;

  @Prop({required: true})
  type!: boolean;

  disabled: boolean = false;
  organizationUnitDisabled: boolean = false;
  defaultData: ProjectInviteCreateDto = {
    displayName: undefined,
    projectSeriesId: undefined,
    projectManagerIds: [],
    year: undefined,
    organizationUnitId: undefined,
    id: 0
  };
  form: any = {...this.defaultData};

  show = false;
  projectSeries: ProjectSeriesDto[] = [];

  get dialogTitle() {
    return this.form!.id ? "编辑" : "新建";
  }

  created() {
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        this.organizationUnitDisabled = true
        await api.projectInvite
          .get({id: this.dataId})
          .then((res: any) => {
            res.year = res.year + "";
            // res.organizationUnitId = [res.organizationUnitId];
            // this.form = { ...res };

            let projectManagerIds: number[] = []
            res.projectManagerList!.map((item: any) => {
              projectManagerIds.push(item.id)
            })
            this.form.projectManagerIds = projectManagerIds;
            debugger
            this.form.id = res.id;
            this.form.displayName = res.displayName;
            if (res.organizationUnitId) {
              this.form.organizationUnitId = [Number(res.organizationUnitId)]
            }
            this.form.year = res.year;
            this.form.projectSeriesId = res.projectSeries!.id
            this.form.id = res.id;
          });
      } else {
        this.organizationUnitDisabled = false
        this.form = {...this.defaultData};
      }
      this.fetchDataDictionary()
    } else {
      this.form = {...this.defaultData};
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  fetchDataDictionary() {
    let isDefault = false;
    if (this.type) {
      isDefault = false;
      this.disabled = false;
    } else {
      isDefault = true;
      this.disabled = true;
    }
    api.projectSeries.getAll({maxResultCount: 14211}).then((res: any) => {
      this.projectSeries = res.items.filter(function (s: ProjectSeriesDto) {
        return s.isDefault === isDefault
      });
      if (isDefault) {
        //外部项目默认选中
        if (this.projectSeries && this.projectSeries.length > 0) {
          this.form.projectSeriesId = this.projectSeries![0].id;
        }
      } else {
        //过滤出属于内部项目的项目系列
        this.projectSeries = this.projectSeries.filter(function (s: ProjectSeriesDto) {
          return s.seriesType === ProjectSeriesType.Internal
        })
      }
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.form.isInternal = this.type;

        if (this.form!.id) {
          await api.projectInvite.update({
            body: this.form
          });
        } else {
          await api.projectInvite.create({
            body: this.form
          });
        }

        this.$message.success("邀约成功");
        this.show = false;
      }
    });
  }

  cancel() {
    this.show = false;
  }

  roleRule = {
    displayName: [
      {
        required: true,
        message: "项目名称必填",
        trigger: "blur"
      }
    ],
    year: [
      {
        required: true,
        message: "年份必选",
        trigger: "blur"
      }
    ],
    projectSeriesId: [
      {
        required: true,
        message: "项目系列必选",
        trigger: "blur"
      }
    ],
    organizationUnitId: [
      {
        required: true,
        message: "合作方机构必选",
        trigger: "blur"
      }
    ]
  };
}
